.modal{
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 1;
  margin: 0;

  &#add-event{
    position: fixed;
    top: unset;
    left: unset;
    width: 60%;
    height: 60%;
    border-radius: 10px;
    right: unset;
    left: 20%;
    background-color: var(--black_1);
    backdrop-filter: blur(12px);
    
    .modal-content{
      width: 100%;
      .modal-body{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: calc(100% - 48px);
        width: 100%;
  
        .quickAdd-container{
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          h4{
            text-align: center;
            font-size: 2em;
            text-transform: uppercase;
          }
          p{
            text-align: center;
            width: 50%;
          }
        }
      }
    }
    

    .modal-footer{
      background-color: unset;
    }
  }

  &.notificaties{
    position: absolute;
    top: unset;
    left: unset;
    width: 300px;
    height: 500px;
    border-radius: 10px;

    &.hidden{
      display: none;
    }
  }

  .modal-header{
    margin-top: 30px;
  }

  .modal-body{
    &>p{
      margin-bottom: 50px;
    }
  }

  .modal-checks{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &>h6{
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .modal-checks-box{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(100% - 20px);

      &>p{
        margin-right: 50px;
        margin-bottom: 30px;
        width: 240px;
      }
    }



    
  }

  .modal-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &>a{
      margin-right: 20px;
    }

    .button{
      margin-left: 10px;
      margin-right: 30px;
    }
  }
}

#imageEditModal {
  display: none;
}

#confirmDelete, #confirmCopy{
  button{
    margin-top: 10px;
    margin-right: 20px;
  }
}
