.head-content, .main-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.main-content{
  align-items: flex-start;

  >.extra-inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >.info-item{
      margin-right: 40px;
    }
  }

  .info-container{
    width: 100%;
    position: relative;

    #blur{
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(245, 245, 245, 0.651);
      backdrop-filter: blur(2px);
      z-index: 2;
    }

    h6{
      margin-top: 40px;

      &:first-child{
        margin-top: 0;
      }
    }

    .info-section{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &:last-child{
        margin-bottom: 50px;
      }

      .info-item{
        width: 20%;
        height: 55px;
        margin: 10px 0;
      }

      .info-item-big{
        width: 100%;
        height: auto;
        margin: 10px 0;
      }
    }
  }
}

.info-title{
  background-color:var(--mystic_purple);
  color: white;
  width: 100%;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
}

.show-times{
  margin: 50px 0 100px 0;
}

.info-item{
  margin: 40px 0;
  display: flex;
  flex-direction: column;
}

.info-item label{
  text-transform: uppercase;
  font-weight: 700;
  color: var(--mystic_purple);
  font-size: 20px;
}

.info-item p{
  margin: 0;
  font-size: 16px;
}

.i-icons{
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a{
    cursor: pointer;
    height: inherit;
    margin: 10px;
    color: var(--mystic_purple) !important;
  }
}

th{
  color: var(--mystic_purple);
  text-transform: uppercase;
}

td{
  padding: 0px 5px;
}

.actions{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &>.utility-bar{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .first-row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      form{
        flex-direction: row;
        padding: 0;
        margin-right: 20px;
      }
    }

    .second-row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.icon-switcher{
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-button{
    font-size: 2em;
    padding: 5px;
    border-radius: 5px;
    color: var(--smokey_black);
    &.active-icon{
      color: var(--backstage_blue);
      background-color: var(--icon_active);
    }
  }
}

.event-list{
  margin-bottom: 50px;
}

.notification-list{
  table.striped{
    tbody{
      td{
        height: 40px;
      }
    }
  }
}

#pNaam{
  width: 25%;
}

#dateC{
  width: 20%;
}

#orgC{
  width: 10%;
}

.status-block{
  padding: 5px 10px;
  text-align: center;
  width: fit-content;;
  &.confirmed{
    background-color: var(--status_green);
  }
  &.concept{
    background-color: var(--status_yellow);
  }
  &.cancelled{
    background-color: var(--status_red);
  }
  &.feestdag{
    background-color: var(--status_purple);
  }
}

.ais-RefinementList{
  z-index: 0;
}

.ais-RefinementList-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .ais-RefinementList-item{
    margin-right: 20px;
  }
}


@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .title{
    h1{
      font-size: 2.8rem;
    }
  }
  .actions{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .utility-bar{
      align-items: center;
      width: 100%;
      .first-row, .second-row{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    >div>.event-add{
      margin-top: 30px;
    }
  }

  .ais-SearchBox-form input[type=search]{
    width: 70vw;
    min-width: unset;
    margin-bottom: 20px;
  }

  .main-content{
    .info-container{
      .info-section{
        flex-direction: column;
        .info-item{
          width: 100%;
        }
      }
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .title{
    h1{
      font-size: 4.2rem;
    }
  }

  .actions{
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
    .utility-bar{
      align-items: flex-start;
      width: unset;
      .first-row, .second-row{
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
      }
    }
    >div>.event-add{
      margin-top: 0;
    }
  }

  .ais-SearchBox-form input[type=search]{
    width: unset;
    min-width: 450px;
    margin-bottom: 0px;
  }

  .main-content{
    .info-container{
      .info-section{
        flex-direction: row;
        .info-item{
          width: 20%;
        }
      }
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
