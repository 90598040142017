@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

h1, h2, h3, h4, h5, h6{
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  margin: 0;
  color: var(--mystic_purple);
  width: fit-content;

  &.label{
    font-size: 15px;
  }
}

h5{
  color: #a3a3a3;
}


.title{
  margin: 70px 0;
  color: var(--mystic_purple);

  h1{
    margin-bottom: 10px;
  }
}

h6.label{
  font-size: 13px;
}
