.button{
  background-color: var(--mystic_purple);
  color: var(--full_light_white);
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: var(--full_light_white);
    color: var(--mystic_purple);

    span{
      color: var(--mystic_purple);
    }
  }

  &:focus{
    background-color: var(--full_light_white);
    color: var(--mystic_purple);

    span{
      color: var(--mystic_purple);
    }
  }
}

.button-alt{
  background-color: var(--full_light_white);
  color: var(--mystic_purple);
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: var(--mystic_purple);
    color: var(--full_light_white);

    span{
      color: var(--full_light_white);
    }
  }

  &:focus{
    background-color: var(--mystic_purple);
    color: var(--full_light_white);

    span{
      color: var(--full_light_white);
    }
  }
}

.button-red{
  background-color: var(--bold_pink);
  color: var(--full_light_white);
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: var(--full_light_white);
    color: var(--bold_pink);

    span{
      color: var(--bold_pink);
    }
  }

  &:focus{
    background-color: var(--full_light_white);
    color: var(--bold_pink);

    span{
      color: var(--bold_pink);
    }
  }
}

button{
  font-family: 'DM Sans', sans-serif;
  padding: 5px 15px;
  border: none;
  
  &:focus{
    background-color: transparent;
  }
}

.event-add{
  display: flex;
  align-items: center;
  color: var(--mystic_purple);
  background: none;
  border: none;
  
  p{
    font-weight: 700;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

.btn-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  color: var(--mystic_purple);
  background-color: var(--full_light_white);
  transition: all 0.3s ease-in-out;

  span{
    color: var(--backstage_purple);
    margin-right: 10px;
  }

  &:hover{
    background-color: var(--mystic_purple);
    color: var(--full_light_white);

    span{
      color: var(--full_light_white);
    }
  }

  &:focus{
    background-color: var(--mystic_purple);
    color: var(--full_light_white);

    span{
      color: var(--full_light_white);
    }
  }
}

.ais-SearchBox-reset{
  background-color: transparent;
  border: none;
  margin: 0;
}

.back-btn{
  margin-top: 50px;
}

.disabledBtn{
  -webkit-box-shadow: var(--disabled_box_shadow);
  box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
  color: #afafaf;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  span{
    color: #afafaf;
  }
}

.ais-InfiniteHits-loading-btns{
  position: absolute;
  margin-top: 10px;
  .ais-InfiniteHits-loadMore{
    margin-left: 20px;
    padding: 6px 12px;
    border: 0px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
    box-shadow: var(--primary_box_shadow);
    color: var(--mystic_purple);
    background-color: var(--full_light_white);
    transition: all 0.3s ease-in-out;
  
    &:hover{
      background-color: var(--mystic_purple);
      color: var(--full_light_white);
    }
  
    &:focus{
      background-color: var(--mystic_purple);
      color: var(--full_light_white);
    }
  }
  
  .ais-InfiniteHits-loadMore--disabled{
    -webkit-box-shadow: var(--disabled_box_shadow);
    box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
    color: #afafaf;
    background-color: #f7f7f7;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
  
  .ais-InfiniteHits-loadPrevious{
    padding: 6px 12px;
    border: 0px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
    box-shadow: var(--primary_box_shadow);
    color: var(--mystic_purple);
    background-color: var(--full_light_white);
    transition: all 0.3s ease-in-out;
  
    &:hover{
      background-color: var(--mystic_purple);
      color: var(--full_light_white);
    }
  
    &:focus{
      background-color: var(--mystic_purple);
      color: var(--full_light_white);
    }
  }
  
  .ais-InfiniteHits-loadPrevious--disabled{
    -webkit-box-shadow: var(--disabled_box_shadow);
    box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
    color: #afafaf;
    background-color: #f7f7f7;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
}

.editImg{
  position: absolute;
  bottom: 0;
  border-radius: 0px 25px 0px 25px;
  padding: 10px 20px 7px 20px;
  color: var(--mystic_purple);
  background-color: var(--full_light_white);
  transition: all 0.3s ease-in-out;

  &:hover{
    color: var(--full_light_white);
    background-color: var(--mystic_purple);
  }
}

.draaiboek-btns{
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  

  .draaiboek-btn{
    background-color: var(--mystic_purple);
    color: var(--full_light_white);
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    border: none;
    text-align: center;
    -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
    box-shadow: var(--primary_box_shadow);
    transition: all 0.3s ease-in-out;
    width: fit-content;
  
    &:hover{
      background-color: var(--full_light_white);
      color: var(--mystic_purple);
  
      span{
        color: var(--mystic_purple);
      }
    }
  
    &:focus{
      background-color: var(--full_light_white);
      color: var(--mystic_purple);
  
      span{
        color: var(--mystic_purple);
      }
    }
  }
}
