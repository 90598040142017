html{
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  font-weight: 200;
  background-color: var(--full_light_white);
  min-height: 100%;
}

canvas{
  width: 100% !important;
  height: 100% !important;
  z-index: -1 !important;
  position: fixed !important;
}

p{
  margin-block-start: 0em;
  margin-block-end: 0em;
  line-height: initial;
}

.flex-container-height{
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.flex-container{
  display: flex;
  flex-direction: row;
  height: auto;
}

.flex-container-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 80px;
}

.container-small{
  padding-top: 80px;
  width: 50%;
}

.container-wide{
  width: 85%;
  margin: auto;
  padding-top: 80px;
}

.container-wide-split{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  width: inherit;
  margin: auto;

  .split-1, .split-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }

  .split-alt-1, .split-alt-2{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
}

.block-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: auto;
  padding: 20px;
  border-radius: 30px;
  background-color: var(--full_light_white);
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  
  &:nth-last-child(1){
    margin-bottom: 0;
  }

  .block-title{
    font-size: 1rem;
    font-weight: 700;
    color: var(--mystic_purple);
    text-transform: uppercase;
  }

  .block-actions > a > svg{
    color: var(--mystic_purple);
  }
}

.greyed-out{
  color: grey;
}

.section{
  padding-top: 80px;
}

svg{
  &.disabled{
    pointer-events: none;
    color: grey;
  }
}

.logo-big{
  width: 100%;
  margin-bottom: 20px;
}

////////////////////////////////////////////////////////////////////////////////
/// SCROLLBAR
////////////////////////////////////////////////////////////////////////////////

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mystic_purple);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--mystic_purple)9f;
}

@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .container-wide{
    width: 95%;
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 
}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  .container-wide{
    width: 85%;
  }
}
