.notification-title{
  padding: 20px 0 10px 10px;
  position: fixed;
  background-color: var(--full_light_white);
  width: 300px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificatie-items{
  padding: 60px 0 20px;
  .notificatie-item{
    padding: 10px;
  
    &>.event-add{
      align-items: unset;
      padding: 0;
      margin-top: 5px;
    }
  }
}
