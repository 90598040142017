.image-round{
  width: 55%;
  background-image: url(../../images/Paaspop2017_HQ.jpg);
  background-position: 70%;
  background-size: cover;
  clip-path: circle(100.0% at 0 50%);
}

.login-container{
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-container{
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>form{
    width: 100%;
  }
}

.submit-bar{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .fgPwd{
    color: var(--mystic_purple);
  }
}

@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */

  .flex-container-height{
    flex-direction: column;
  }

  .image-round{
    display: block;
    height: 30%;
    width: 100%;
    clip-path: none;
  }

  .login-container{
    width: 100%;

    >form{
      width: 100%;

      .form-title{
        h5{
          font-size: 2.5em;
        }
        h6{
          font-size: 1.5em;
        }
      }
    }
  }

  .signup-container{
    width: 100%;
  }
}

@media (min-width:641px)  {
   /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .flex-container-height{
    flex-direction: row;
    height: 100vh;
  }

  .image-round{
    display: block;
    height: 100%;
    width: 55%;
    clip-path: circle(100.0% at -90% 50%);
  }

  .login-container{
    width: 45%;
    form{
      width: 60%;
    }
  }

  .signup-container{
    width: 50%;
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

  .image-round{
    display: block;
    clip-path: circle(100.0% at -20% 50%);
  }
}
@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  .image-round{
    clip-path: circle(100.0% at 0% 50%);
  }

  .login-container{
    width: 45%;
    form{
      width: 45%;
    }
  }
}
