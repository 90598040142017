.nav-wrapper{
  background-color: var(--full_light_white_9);
  color: var(--mystic_purple);
  position: fixed;
  width: 85%;
  margin: 30px 7.5% 0 7.5%;
  border-radius: 40px;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);

  .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--mystic_purple);

    .navBtn-icon{
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: var(--mystic_purple);
      background-color: transparent;
    }
    .brand-logo{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--mystic_purple);
      margin-left: 10px;

      .logo{
        height: 50px;
        margin-right: 20px;
      }

      .logo-extra{
        position: absolute;
        transform: rotateZ(374deg);
        width: 80px;
        top: -28px;
        left: -4px;

        &>img{
          width: inherit;
        }
      }
    }
    .btn-floating{
      background-color: var(--mystic_purple);
    }
  }
}

nav ul a{
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: var(--steel_teal) !important;
  }
}

nav .brand-logo{
  position: relative;
}

.help-block{
  background-color: var(--full_light_white);
  position: absolute;
  top: 70px;
  padding: 20px;
  color: var(--mystic_purple);
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);

  >p{
    margin-block-start: 0;
    margin-block-end: 0;
  }
  
  >a{
    transition: none;
    color: var(--mystic_purple);
    padding: 0;
    cursor: default;

    &:hover{
      background-color: transparent;
    }
  }

  &.hidden{
    display: none;
  }
}





@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .nav-wrapper{
    width: 90vw;
    margin: 30px 5vw 0 5vw;
    .container{
      width: 100%;
      max-width: 100vw;
      justify-content: center;
      .brand-logo{
        display: none;
      }
      ul{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
      }
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .nav-wrapper{
    width: 90vw;
    margin: 30px 5vw 0 5vw;
    .container{
      justify-content: space-between;
      .brand-logo{
        display: flex;
      }
      ul{
        display: block;
        width: unset;
      }
    }
  }
}
