:root {
  --full_light_white: #fff;
  --smokey_grey: #efefef;
  --smokey_black: #cbcbcb;
  --steel_teal: #acd2d4;
  --ultra_blue: #00d1f1;
  --icon_active: #47146c1c;
  --intense_purple: #7500c2;
  --mystic_purple: #530e79;
  --backstage_blue: #2a0b3a;
  --bold_pink: #f52566;
  --black: #000000;

  --full_light_white_9: #ffffffe6;
  --black_1: #0000001a;

  --status_green: #09df2665;
  --status_yellow: #dad41465;
  --status_red: #df460965;
  --status_purple: #7500c265;

  --primary_box_shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.3);
  --disabled_box_shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3); 
}


/* GLOBAL */
@import './scss/global/global';
@import './scss/global/typography';
@import './scss/global/buttons';

/* COMPONENTS */
@import './scss/components/nav';
@import './scss/components/form';
@import './scss/components/signIn';
@import './scss/components/input';
@import './scss/components/index';
@import './scss/components/search';
@import './scss/components/images';
@import './scss/components/modal';
@import './scss/components/calendar';
@import './scss/components/account';
@import './scss/components/notificatie';
@import './scss/components/hit';
