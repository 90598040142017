.header{
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 34px;
  box-shadow: var(--primary_box_shadow);
  border-radius: 25px;
  
  .factuur-info{
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 500px;
    padding: 20px;
    background-color: var(--full_light_white_9);
    border-radius: 25px;

    &>h4{
      color: var(--mystic_purple);
      margin-bottom: 30px;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    a{
      color: var(--mystic_purple);
    }
  }

  .header-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 25px;
  }
}


@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .header{
    flex-direction: column;
    .factuur-info{
      position: relative;
      width: 100%;
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .header{
    flex-direction: row;
    .factuur-info{
      position: absolute;
      width: 320px;
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
