form{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.full-width{
    width: 100%;
  }

  .form-title{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;

    h5, h6{
      text-transform: uppercase;
      color: var(--mystic_purple);
    }
  
    h5{
      font-size: 48px;
    }
  
    h6{
      font-size: 30px;
    }

  }

  .input-field{
    width: 100%;
    text-align: center;

    & .helper-text{
      text-align: left;
    }

    &.small{
      width: 45%;
    }
    
    &.big{
      width: 94%;
    }

    &.extra-wide{
      width: 100%;
      padding: 20px;

      .input-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .input-wrapper{
          width: 48%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          >.input-field{
            width: 48%;
          }
          
        }
      }
    }
  }

  button{
    margin: 20px 0;
  }

  .form-team{
    align-self: flex-start;
  }

  .form-function{
    align-self: flex-start;
  }

  .checkboxes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .checkboxes > p{
    margin-right: 50px;
  }
}


.ais-SearchBox-form{
  
  input[type=search]{
    border: none;
    background-color: var(--full_light_white);
    margin: 0;
    padding: 9px 12px;
    height: unset;
    border-radius: 5px;
    box-shadow: var(--primary_box_shadow);
    font-size: 15px;
    min-width: 450px;

    &:focus{
      border-bottom: 0 !important;
      box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.3) !important;
    }
  }

  .ais-SearchBox-submit{
    display: none;
  }
}

.ais-SortBy{
  width: 150px;
  margin-right: 20px;

  .ais-SortBy-select{
    border: none;
    background-color: var(--full_light_white);
    margin: 0;
    padding: 9px 12px;
    height: unset;
    border-radius: 5px;
    box-shadow: var(--primary_box_shadow);
    font-size: 15px;
  
    &:focus{
      outline: none;
    }
  }
}
