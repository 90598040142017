label{
  text-transform: uppercase;
  font-size: 0.8rem !important;

  &.active{
    color: var(--mystic_purple);
  }
}

select{
  display: block;
}

.select{
  width: 100%;

  &.small{
    width: 45%;
  }
}

.select-label{
  position: relative;
  font-size: 1rem;
}

[type="checkbox"].filled-in:checked+span:not(.lever):after{
  border: 2px solid var(--mystic_purple);
  background-color: var(--mystic_purple);
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: var(--mystic_purple);
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: var(--mystic_purple) !important;
}

input[type=date]:not(.browser-default){
  background-color: var(--full_light_white);
  border-bottom: none;
  padding: 0px 15px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  width: calc(100% - 30px);
  height: 2.5rem;
  margin: 0;
  border-radius: 5px;
}

input[type=date]:not(.browser-default):focus:not([readonly]){
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: var(--primary_box_shadow);
  border-bottom: none;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after{
  background-color: var(--mystic_purple);
  border-color: var(--mystic_purple);
}

#startdatum, #einddatum{
  height: 3rem;
  width: calc(100% - 30px);
}

.css-g1d714-ValueContainer{
  height: 40px;
}

.upload{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &>label {
    position: relative;
    text-align: left;
    margin: 0px 20px 25px 0px;
    top: auto;
    left: auto;
  }
}
.switch{
  margin: 0 20px;
}

.switch label input[type=checkbox]:checked+.lever{
  background-color: #efefef;

  &::after{
    background-color: #efefef;
  }
}

.switch label .lever{
  background-color: var(--mystic_purple) !important;
}

.date-filter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin-right: 20px;
}

.radio-group{
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .radio-btn{
    margin-right: 20px;
    margin-bottom: 10px;
  }
}



@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .date-filter{
    width: 100%;
    .date-container{
      width: 135px;
    }
  }
}
@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .date-filter{
    width: 450px;
    .date-container{
      width: unset;
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
